import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    "Changed by": "Changed by",
  },
  da: {
    translation: {
      "Portalias was created": "Portalias blev oprettet",
      "Portalias was deleted!": "Portalias blev slettet",
      "Portalias was updated!": "Portalias blev opdateret",
      "Portalias could not be deleted": "Portalias kunne ikke slettes",
      "Agreementportal is loading": "Aftaleportalen indlæses",
      "Deleting the last service will delete entire servicewindow": "Sletning af den sidste service vil slette hele service vinduet",
      "Are you sure you wish to delete the servicewindow?": "Er du sikker på at du ønsker at slette servicevinduet?",
      "Show all": "Vis alle",
      "Latest changes": "Seneste ændringer",
      "Pick services": "Vælg services",
      "Pick clients": "Vælg klienter",
      "Agreement info": "Aftale informationer",
      "First name": "Fornavn",
      "Next": "Næste",
      "Previous": "Forrige",
      "Last name": "Efternavn",
      "Contact was removed from service!": "Kontakt blev fjernet fra service",
      "Contact was removed from client!": "Kontakt blev fjernet fra klienten",
      "Contact was attached": "Kontakt blev tilknyttet",
      "Contact could not be attached": "Kontakt kunne ikke tilknyttes",
      "You have to select an organisation": "Du skal vælge en organisation",
      "Attach contact": "Tilknyt kontakt",
      "Mark as unread": "Marker som ulæst",
      "Mark as read": "Marker som læst",
      "Notifications": "Notifikationer",
      "more": "mere",
      "Change organisations": "Skift organisationer",
      "Change owner -or administrating organisation": "Skift ejer -eller administrerende organisation",
      "Create contact with user": "Opret kontakt med bruger",
      "Contact without user": "Kontakt uden bruger",
      "Contact with user": "Kontakt med bruger",
      "Open contact without user": "Åben kontakt uden bruger",
      "No results was found": "Ingen resultater fundet",
      "Notification was updated!": "Notifikationen blev opdateret",
      "Remark": "Bemærkning",
      "Time": "Tid",
      "Role": "Rolle",
      "Authorization could not be created": "Autorisation kunne ikke oprettes",
      "Authorization was created": "Autorisation blev oprettet",
      "Contact label": "Kontakt label",
      "Contact labels": "Kontakt labels",
      "All is good!": "Alt er godt",
      "An error occured ": "Der opstod en fejl",
      "The values provided requires enforcement": "De indtastede værdier kræver gennemtvingelse",
      "Missing": "Mangler information",
      "Signup": "Tilmeld",
      "Open user attached to contact": "Åben brugeren der er knyttet til kontakten",
      "Select an organisation to create a service": "Vælg en organisation, for at oprette en ny service",
      "Open in new tab": "Åben i ny fane",
      "this is the footer": "Dette er foden",
      "Latest change": "Seneste ændring",
      "Agreementportal": "Aftaleportal",
      "Agreementportal documentation": "Dokumentation",
      "Accessibility Statement": "Tilgængelighedserklæring",
      "Organisations": "Organisationer",
      "Organisation": "Organisation",
      "Description": "Beskrivelse",
      "Changed by": "Ændret af",
      "Changed": "Ændret",
      "Agreements": "Aftaler",
      "agreements": "aftaler",
      "Clients": "Klienter",
      "clients": "klienter",
      "Routers": "Routere",
      "Authorization": "Autorisation",
      "Contact": "Kontakt",
      "Name": "Navn",
      "Full name": "Fulde navn",
      "Group": "Gruppe",
      "Username": "Brugernavn",
      "Yes": "Ja",
      "No": "Nej",
      "Phonenumber": "Telefonnummer",
      "LDAP Name": "LDAP Navn",
      "ACL Name": "ACL Navn",
      "BGP Activated": "BGP Aktiveret",
      "IP-address": "IP-Adresse",
      "Title": "Titel",
      "Contacts": "Kontakter",
      "Details": "Detaljer",
      "Required": "Påkrævet",
      "Lowercase and numbers": "Må kun indeholde små bogstaver og tal",
      "Invalid email": "Ugyldig email",
      "Edit": "Rediger",
      "Delete": "Slet",
      "Save": "Gem",
      "Reset": "Nulstil",
      "Not a valid IP-Address": "Ikke en gyldig IP-Adresse",
      "Not a valid url": "Ikke en gyldig URL",
      "Error": "Fejl",
      "Not found": "Ingen resultater",
      "Actions": "Handlinger",
      "Log out": "Log ud",
      "Change password": "Skift adgangskode",
      "Active": "Aktiv",
      "Unused": "Ubrugt",
      "Are you sure you wish to delete this ressource?": "Er du sikker på at du ønsker at slette denne ressource?",
      "Delete ressource?": "Slet ressource?",
      "Split view": "Splittet visning",
      "Unified view": "Forenet visning",

      "ACTIVE": "AKTIV",
      "LOCKED": "LÅST",
      "INACTIVE": "INAKTIV",
      "DELETED": "SLETTET",
      "Start": "Start",
      "End": "Slut",
      "Created by": "Oprettet af",
      "Created": "Oprettet",
      "AccessControlList was created": "Access control list blev oprettet",
      "AccessControlList was updated!": "Access control list blev opdateret",


      "Are you sure you wish to remove this ip address?": "Er du sikker på du ønsker at fjerne denne ip adresse?",
      "Are you sure you wish to remove this contact?": "Er du sikker på du ønsker at fjerne denne kontakt?",
      "Are you sure you wish to remove this dns suffix?": "Er du sikker på du ønsker at fjerne dette dns suffiks?",
      "Are you sure you wish to remove this hosting site?": "Er du sikker på du ønsker at fjerne dette hosting site?",
      "Are you sure you wish to remove this router?": "Er du sikker på du ønsker at fjerne denne router?",
      "Are you sure you wish to remove this subnet?": "Er du sikker på du ønsker at fjerne dette subnet?",
      "Are you sure you wish to remove this endpoint?": "Er du sikker på du ønsker at fjerne dette endpoint?",
      "Are you sure you wish to delete this entity?": "Er du sikker på at du ønsker at slette denne entitet?",
      "Are you sure you wish to delete this ip address?": "Er du sikker på at du ønsker at slette denne ip addresse?",
      "Are you sure you wish to delete this subnet?": "Er du sikker på at du ønsker at slette dette subnet?",
      "Are you sure you wish to delete this dns suffix?": "Er du sikker på at du ønsker at slette dette dns suffiks?",
      "Are you sure you wish to delete this router?": "Er du sikker på at du ønsker at slette denne router?",
      "Are you sure you wish to delete this smokeping?": "Er du sikker på at du ønsker at slette denne smokeping?",
      "Are you sure you wish to delete this ACL?": "Er du sikker på at du ønsker at slette denne ACL?",
      "Are you sure you wish to delete this endpoint?": "Er du sikker på at du ønsker at slette dette endpoint?",
      "Are you sure you wish to delete this service approver?": "Er du sikker på at du ønsker at slette denne servicegodkender?",
      "Are you sure you wish to delete this service?": "Er du sikker på at du ønsker at slette denne service?",
      "Are you sure you wish to remove this service from the service window?": "Er du sikker på at du ønsker at fjerne denne service fra dette servicevindue?",
      "Are you sure you wish to delete this user?": "Er du sikker på at du ønsker at slette denne bruger?",
      "Are you sure you wish to delete this external contact?": "Er du sikker på at du ønsker at slette denne email kontakt?",
      "Are you sure you wish to delete this authorisation?": "Er du sikker på at du ønsker at slette denne autorisation?",
      "Are you sure you wish to delete this hostingsite?": "Er du sikker på at du ønsker at slette dette hostingsite? Der vil samtidig blive slettet {{numberOfClients}} klienter og {{numberOfServices}} services",
      "Are you sure you wish to delete this portalias?": "Er du sikker på at du ønsker at slette dette portalias?",
      "Are you sure you wish to delete this service window?": "Er du sikker på at du ønsker at slette dette servicevindue?",
      "Are you sure you wish to delete this CPE?": "Er du sikker på at du ønsker at slette denne CPE?",


      "Are you sure you wish to delete all agreements on this client?": "Er du sikker på du ønsker at slette alle {{numberOfAgreements}} aftaler på denne klient?",
      "Are you sure you wish to delete all agreements on this hostingsite?": "Er du sikker på du ønsker at slette alle {{numberOfAgreements}} aftaler på dette hosting site?",
      "You will not be able to undo this action": "Denne handling kan ikke fortrydes",
      "Delete all agreements": "Slet alle aftaler",
      "Finish service window": "Færdiggør servicevindue",
      "Are you sure you wish to finish the service window?": "Er du sikker på at du ønsker at færdiggøre dette servicevindue?",

      //Organisation
      "General contact email": "Overordnet kontaktperson email",
      "Service desk email": "Servicedesk email",
      "Service desk telephone number": "Servicedesk telefon",
      "Hotline telephone number": "Vagttelefon",
      "Max 15 long": "Telefonnummeret kan være maks 15 tegn",
      "Danish telephone number": "Telefonnummeret skal følge dansk format: eventuelt starte med +45, efterfulgt af 8 tal som kan have - eller mellemrum i sig",
      "Organisation information": "Organisation information",
      "Details - Organisation": "Detaljer - Organisation",
      "DNS-suffix": "DNS suffiks",
      "Service monitorering": "Service Overvågning",
      "Add new smokeping": "Tilføj ny smokeping",
      "Create organisation": "Opret ny organisation",
      "Organisation was updated!": "Organisationen blev opdateret",
      "Organisation was created": "Organisationen blev oprettet",
      "Organisation was deleted!": "Organisationen blev slettet",
      "Delete organisation": "Slet organisationen",
      "Delete user": "Slet bruger",
      "Create router": "Opret router",
      "Edit router": "Rediger router",
      "Edit smokeping": "Rediger smokeping",
      "Create subnet": "Opret subnet",
      "Edit subnet": "Rediger subnet",
      "Subnet was created": "Subnet blev oprettet",
      "Not a valid CIDR": "Ugyldig CIDR",
      "Hostingsite was created!": "Hostingsite blev oprettet",
      "Smokeping was created": "Smokeping blev oprettet",
      "Create smokeping": "Opret smokeping",
      "Router was created": "Router blev oprettet",
      "Dns suffix was deleted": "Dns suffix blev slettet",
      "DnsSuffix was created": "Dns suffiks blev oprettet",
      "Address": "Adresse",
      "Edit dns": "Rediger dns",
      "Create dns": "Opret dns",
      "Edit ipaddress": "Rediger IP-adresse",
      "Create contact": "Opret kontakt",
      "Edit contact": "Rediger kontakt",
      "Create authorisation": "Opret autorisation",
      "Edit authorisation": "Rediger autorisation",
      "Chose organisation": "Vælg organisation",
      "address": "adresse",
      "Owner organisation": "Ejer organisation",
      "Administration organisation": "Administrativ organisation",
      "No organisation": "Ingen organisation",
      "General contact information": "Yderlige kontakt informationer",
      "Router was updated!": "Router blev opdateret",
      "DnsSuffix was updated!": "Dns suffix blev opdateret",
      "Router was deleted!": "Router blev slettet",
      "Are you sure you wish to delete this organisation?": "Er du sikker på at du ønsker at slette denne organisation?",
      "Allow existing DNS suffixes": "Tillad eksisterende DNS suffixes",

      //Service
      "EMERGENCY": "Akut",
      "JOURNAL": "Journal",
      "LPS": "LPS",
      "LAB": "Lab",
      "IDP": "IDP",
      "RIS_PACS": "RIS/PACS",
      "REPORT": "Indberet",
      "VIDEO": "Video",
      "INFRASTRUCTURE": "Infrastruktur",
      "PLATFORM": "Platforme",
      "SPECIALTIES": "Specialer",
      "PHARMACY": "Apotek",
      "SHARED": "Shared",
      "TRANSPORT": "Transport",
      "RESEARCH": "Forskning",
      "Create": "Opret",
      "Authorisation to": "Autorisation til",
      "User does not exist in keycloak": "Bruger findes ikke i keycloak",
      "Categories": "Kategorier",
      "Emergency systems": "Akut systemer",
      "Journal systems - Patient administrative systems": "Journalsystemer - Patient administrative systemer",
      "General practice systems": "Læge Praksis systemer",
      "Laboratory systems and laboratory data: Labka, miba, Biochemistry..": "Laboratoriesystemer, og laboratoriedata: Labka, miba, Biokemi..",
      "Identity services. ADFS, Radius, related to federations, seb,": "Identitets services. ADFS, Radius, relateret til føderationer, seb,",
      "Radiology systems and Radiology Information Systems": "Røntgen systemer og Røntgen Informationssystemer",
      "Reporting and collection of clinical data, KMS, etc.": "Indberetninger og opsamling af kliniske data, KMS, etc.",
      "Video conference systems, VDX, Bridgit and similar.": "Video konference systemer, VDX, Bridgit og lign.",
      "Infrastructure. Supporting services, DNS, monitoring, scanning,": "Infrastruktur. Understøttende services, DNS, overvågning, skanning,",
      "Platforms, NSP, sundhed.dk etc.": "Platforme, NSP, sundhed.dk etc.",
      "Specialty-specific systems, DBCG, cardiology,": "Speciale specifikke systemer, DBCG, kardiologi,",
      "Pharmacy and medicine related": "Apotek og medicin relateret",
      "Shared care, collaboration across": "Shared care, samarbejde på tværs",
      "Flex traffic, taxi, helicopter.": "Flextrafik, taxa, helikopter.",
      "Research-oriented systems": "Systemer, der er forskningsrettede",
      "Start typing to create a new servicetag": "Begynd at skrive for at oprette en ny servicegruppe",
      "Not a valid date": "Ikke en valid dato",
      "End time must be after start time": "Slut dato skal være efter start dato",
      "Servicewindow is completed": "Servicevindue er gennemført",
      "Servicewindow is planned": "Servicevindue er planlagt",
      "Servicewindow is in progress": "Servicevindue er igang",
      "You have to pick a hostingsite before a servicegroup": "Du skal vælge et hostingsite, før du vælger servicegruppe",
      "Ports and protocols": "Porte og protokoller",
      "Change hostingsite": "Skift hostingsite",
      "ipAddress is not in subnet": "IP-adresse er ikke en del af subnettet",
      "Hosting site should be in the new organisation": "Hosting site skal tilhøre den nye organisation",
      "Subnet should be in the new hostingsite": "Subnet skal tilhøre det nye hostingsite",
      "Dns suffix should be in the new hostingsite": "Dns suffix skal tilhøre det nye hostingsite",
      "Endpoints should be using subnets from the new hostingsite": "Alle endpoints skal være tilknyttet et subnet fra det nye hostingsite",
      "Service should be using service tag from new hosting site, if added": "Hvis service gruppen udfyldes, skal den være fra det nye hostingsite",
      "Endpoint was created": "Endpoint blev oprettet",
      "No endpoints in service": "Ingen endpoints på service",
      "Create service": "Opret ny service",
      "Create endpoint": "Opret nyt endpoint",
      "Invalid DNS name": "Ugyldigt DNS navn",
      "Service was updated!": "Servicen blev opdateret",
      "Service was created": "Servicen blev oprettet",
      "The service was deleted!": "Servicen er slettet",
      "Delete service": "Slet service",
      "Delete portalias": "Slet portalias",
      "Criticality score": "Kritikalitets score",
      "Propagation": "Udbredelse",
      "Criticality": "Kritikalitet",
      "Criticality information": "Kritikalitets information",
      "Select the subnet of the health data network (SDN) IP addresses from which this service endpoint is to be displayed. This is your organization's external subnet on the health data network and not the organization's internal IP. If your organization has only one subnet on SDN, this is pre-filled.": "Vælg det subnet af sundhedsdatanet (SDN) IP-adresser, som dette service endpoint skal udstilles fra. Dette er din organisationens eksterne subnet på sundhedsdatanettet og ikke organisationens interne IP. Hvis din organisation kun har et subnet på SDN, er dette forudfyldt.",
      "Servicegroup": "Service gruppe",
      "Requires approval": "Godkendelse påkræves",
      "Uses encryption": "Benytter kryptering",
      "Service window rules": "Regler for servicevinduer",
      "New approver": "Ny godkender",
      "Create service tag": "Opret service tag",
      "Service tags could not be fetched": "Service tags kunne ikke hentes",
      "Service tag could not be created": "Service tag kunne ikke oprettes",
      "Service tag was created": "Service tag blev oprettet",
      "Showing for": "Viser for",
      "Attach service to service window": "Tilknyt service til servicevindue",
      "Service was removed from service window": "Service blev fjernet fra servicevindue",
      "Open user in another tab": "Åben bruger i ny fane",
      "Service was added to service window": "Service blev tilføjet til servicevindue",
      "Reason for no encryption": "Begrundelse for ingen kryptering",
      "NOT_USED": "Ikke brugt",
      "Reset search": "Nulstil søgning",
      "Start date": "Start dato",
      "End date": "Slut dato",
      "USED": "Brugt",
      "Search": "Søg",
      "Advanced search": "Avanceret søgning",
      "Sort by": "Sorter efter",
      "Order by": "Sorteringsretning",
      "Service approvers could not be fetched": "Service godkendere kunne ikke hentes",
      "Service approver could not be fetched": "Service godkender kunne ikke hentes",
      "Service approver was updated!": "Service godkender blev opdateret",
      "Service approver could not be updated": "Service godkender kunne ikke opdateres",
      "Service approver was created": "Service godkender blev oprettet",
      "Service approver could not be created": "Service godkender kunne ikke oprettes",
      "Service approver was deleted!": "Service godkender blev slettet",
      "Service approver could not be deleted": "Service godkender kunne ikke slettes",
      "Subnet does not exist in hostingsite": "Subnetet eksisterer ikke på hostingsite",
      "Hosting site has no subnets": "Hosting site har ingen subnets",
      "No available portaliases": "Ingen tilgængelige port alias",
      "Start typing to get suggestions": "Begynd at skrive for at få forslag",
      "You have to select at least one service": "Der skal vælges mindst én service",
      "No available dns suffixes": "Ingen tilgængelige dns suffix",
      "If no approvers are selected, the administrators for the service will be selected as default.": "Hvis der ikke vælges nogle godkendere, vil servicens administratorer blive valgt som standard.",
      "SLA description": "SLA beskrivelse",
      "Uses encryption approval": "Anvendes kryptering?",
      "No ports": "Ingen porte",
      "Loading authorisation...": "Indlæser autorisation...",
      "Authorization was used": "Autorisation blev benyttet",
      "Authorization could not be used": "Autorisation kunne ikke benyttes",
      "At least one ip address should be added": "Der skal mindst tilføjes én ip addresse",
      "At least one contact should be added": "Der skal mindst tilføjes én kontakt",
      "You have to select at least one client": "Der skal vælges mindst én klient",
      "You have to select at least one context": "Der skal vælges mindst én kontekst",
      "At least one label should be added": "Der skal vælges mindst én label",
      "At least one port should be added": "Der skal vælges mindst én port",
      "Approvers": "Godkendere",
      "Create approver": "Opret godkender",
      "BENEFICIAL": "Gavnlig",
      "IMPORTANT": "Vigtig",
      "CRITICAL": "Kritisk",
      "NIS": "NIS",
      "TEST_AND_TRAINING": "Test og træning",
      "LOCAL": "Lokalt",
      "TRANSVERSE": "Tværgående",
      "NATIONAL": "Nationalt",
      "Start time": "Start tid",
      "End time": "Slut tid",
      "In organisation": "I organisationen",
      "Affects clients": "Påvirker klienter",
      "Service Windows": "Servicevinduer",
      "Create service anyways?": "Skal servicen oprettes alligevel?",
      "Endpoint was updated!": "Endpoint blev opdateret",
      "Endpoint was deleted!": "Endpoint blev slettet",
      "DNS preview": "DNS forhåndsvisning",
      "Alarming": "Alarmering",
      "Monitoring": "Overvågning",
      "SLA Description": "SLA beskrivelse",
      "Service was deleted!": "Servicen blev slettet",
      "Edit endpoint": "Rediger endpointet",
      "DNS names": "DNS navne",
      "Hosting site should be changed": "Hosting site skal ændres",

      //Hostingsites
      "Delete hostingsite": "Slet hostingsite",
      "Create hostingsite": "Opret nyt hostingsite",
      "Edit hostingsite": "Rediger hostingsite",
      "Create hosting site": "Opret hosting site",
      "Select an organisation to create": "Vælg en organisation for at oprette",
      "Hostingsite eas updated!": "Hostinsite blev opdateret",
      "Subnet was updated!": "Subnet blev opdateret",
      "Subnet was deleted!": "Subnet blev slettet",
      "Hosting site information": "Hosting site informationer",
      "Theft mark": "Tyverimærke",
      "Theft marks": "Tyverimærker",
      "Vlan name": "VLAN navn",
      "Vlan names": "VLAN navne",
      "SDN V4 IDs": "SDN V4 ID'er",
      "Vlan format": "VLAN navn må kun bestå af små og store bogstaver, tal, '_', og '-'",
      "CPEs could not be fetched": "CPEer kunne ikke hentes",
      "CPE was updated!": "CPE blev opdateret",
      "CPE could not be updated": "CPE kunne ikke opdateres",
      "CPE was created": "CPE blev oprettet",
      "CPE could not be created": "CPE kunne ikke oprettes",
      "CPE was deleted": "CPE blev slettet",
      "CPE could not be deleted": "CPE kunne ikke slettes",
      "Create cpe": "Opret CPE",
      "Edit cpe": "Rediger CPE",
      "Add new CPE entry": "Tilføj ny CPE",
      "Already in hosting site": "Findes allerede på hosting sitet",

      //Client
      "Client was deleted!": "Klient blev slettet",
      "Delete client": "Slet klient",
      "Client name": "Klient navn",
      "Ip address is already in use": "Ip adresse er allerede i brug",
      "Create ip address anyways?": "Skal ip adressen oprettes aligevel?",
      "Client was created!": "Klient blev oprettet",
      "Clients in": "Klienter i",
      "Force save": "Gennemtving gem",
      "Create client": "Opret ny klient",
      "Create ipaddress": "Opret ip adresse",
      "Internal description": "Intern beskrivelse",
      "Descriptions": "Beskrivelser",
      "Ip address could not be fetched": "Ip adresse kunne ikke hentes",
      "Ip address was created!": "Ip adresse er oprettet",
      "Ip address could not be created": "Ip adresse kunne ikke oprettes",
      "Ip address could not be deleted": "Ip adresse kunne ikke slettes",
      "Clients could not be fetched": "Klienter kunne ikke hentes",
      "Client could not be fetched": "Klient kunne ikke hentes",
      "Client ip-addresses": "Klient Ip adresser",
      "IP addresses": "Ip adresser",
      "Are you sure you wish to delete this client?": "Er du sikker på at du ønsker at slette denne klient?",
      "Client could not be created": "Klienten kunne ikke oprettes",
      "Client was updated!": "Klient blev opdateret",
      "Ip address was updated!": "Ip adresse blev opdateret",
      "Ip address was deleted!": "Ip adresse blev slettet",


      //Service window
      "Create service window": "Opret servicevindue",
      "Service windows could not be fetched": "Servicevinduer kunne ikke hentes",
      "Service windows": "Servicevinduer",
      "services effected": "påvirkede services",
      "No service windows are planned!": "Ingen planlagte servicevinduer",
      "Service window": "Servicevindue",
      "Delete service window": "Slet servicevindue",
      "Remove service from service window": "Fjern service fra service vindue",
      "Service window was updated!": "Servicevindue blev opdateret",
      "Service window was deleted!": "Servicevindue blev slettet",
      "Service window was created": "Servicevindue blev oprettet",

      //Events
      "Events": "Hændelser",
      "Overview of events belonging to": "Overblik over hændelser tilhørende",
      "Events belonging to": "Hændelser tilhørende",

      //Agreement
      "The organisation has active agreements, that needs to be terminated in order to delete organisation": "Organisationen har aktive aftaler, som skal afsluttes inden organisationen kan slettes",
      "The hosting site has active agreements, that needs to be terminated in order to delete hosting site": "Hosting site har aktive aftaler, som skal afsluttes inden hosting site kan slettes",
      "The client has agreements, that needs to be terminated in order to delete client": "Klienten har aftaler, som skal afsluttes inden klienten kan slettes",
      "Agreements for hosting site could not be fetched": "Aftaler for hosting site kunne ikke hentes",
      "Agreements for organisation and service approver could not be fetched": "Aftaler for organisation og service godkender kunne ikke hentes",
      "Agreements for organistation could not be fetched": "Aftaler for organisation kunne ikke hentes",
      "Delete agreement": "Slet aftale",
      "Delete agreement request": "Slet anmodning",
      "Are you sure you wish to delete this agreement?": "Er du sikker på at du ønsker at slette denne aftale?",
      "Agreements belonging to": "Aftaler tilhørende",
      "Are you sure you wish to deny this agreement?": "Er du sikker på du ønsker at afvise denne aftale?",
      "Are you sure you wish to terminate this agreement?": "Er du sikker på du ønsker at annullere denne aftale?",
      "Are you sure you wish to approve this agreement?": "Er du sikker på du ønsker at godkende denne aftale?",
      "Overview of agreements belonging to": "Overblik over aftaler tilhørende",
      "Clients belonging to": "Klienter tilhørende",
      "Overview of clients belonging to": "Overblik over klienter tilhørende",
      "Services belonging to": "Services tilhørende",
      "Overview of services belonging to": "Overblik over services tilhørende",
      "Client organisation": "Klient organisation",
      "Terminate agreement": "Annuller aftale",
      "Dismiss agreement request": "Afvis anmodning",
      "Comment": "Kommentar",
      "Are you sure you wish to delete the agreement?": "Er du sikker på du ønsker at slette aftalen?",
      "Are you sure you wish to delete the agreement request?": "Er du sikker på du ønsker at slette anmodningen?",
      "Create agreement": "Opret ny aftale",
      "Cancel": "Annuller",
      "Approval": "Godkendelse",
      "Client Ip addresses": "Klient IP-adresser",
      "No connections": "Ingen forbindelser",
      "Connections": "Forbindelser",
      "Request was approved!": "Anmodning blev godkendt",
      "Request was denied!": "Anmodning blev afvist",
      "Request could not be denied": "Anmodning kunne ikke afvises",
      "Request could not be approved": "Anmodning kunne ikke godkendes",
      "Create agreement request": "Opret ny anmodning",
      "Agreement": "Aftale",
      "Agreement requests": "Anmodninger",
      "Dismissed agreement requests": "Afviste anmodninger",
      "Agreement request was created!": "Anmodning blev oprettet",
      "Agreement request could not be created": "Anmodning kunne ikke oprettes",
      "Approve agreement request": "Godkend anmodning",
      "Agreement request information": "Anmodnings Information",
      "Approval comment": "Kommentar",
      "Approved": "Godkendt",
      "Approved at": "Godkendt den",
      "Edit password": "Skift adgangskode",
      "Requested": "Anmodet",
      "Denied at": "Afvist den",
      "Approved by": "Godkendt af",
      "Denied by": "Afvist af",
      "Requested by": "Anmodet af",
      "Agreement information": "Aftale informationer",
      "Service information": "Service informationer",
      "Client information": "Klient informationer",
      "Contact information": "Kontakt informationer",
      "Client": "Klient",
      "Client IP": "Klient IP",
      "Request new agreement": "Anmod om ny aftale",
      "PENDING": "AFVENTER",
      "APPROVED": "GODKENDT",
      "DENIED": "AFVIST",
      "No allowed ports on service": "Ingen tilladte porte på service",
      "TERMINATED": "ANULLERET",
      "Agreement was deleted": "Aftale blev slettet",
      "Provision status": "Provisioneringsstatus",
      "Provision time": "Provisioneringstid",
      "PROVISIONED": "Provisioneret",
      "PROVISION_FAILED": "Provisionering fejlet",
      "DEPROVISIONED": "Afgivet",
      "Force provision": "Gennemtving provisionering",
      "Are you sure you wish to force provision of all agreements on this hosting site?": "Er du sikker på du ønsker at gennemtvinge provisionering af alle {{numberOfAgreements}} aftaler på dette hosting site?",
      "Agreements on hosting site marked for provision": "Aftaler på hosting sitet markeret til provisionering",
      "Agreements could not be marked for provision": "Aftaler på hosting sitet kunne ikke markeres til provisionering",
      "Provision in progress": "Provisionering i gang",
      "No agreements": "Ingen aftaler",
      "Not an SDN V4 hosting site": "Ikke et SDN V4 hosting site",

      //Users
      "Latest activity": "Seneste aktivitet",
      "Latest login": "Seneste login",
      "Roles": "Roller",
      "Users": "Brugere",
      "users": "brugere",
      "Minimum one character": "Minimum én karakter",
      "User": "Bruger",
      "Create user": "Opret bruger",
      "Password": "Adgangskode",
      "User information": "Bruger informationer",
      "User was updated": "Brugeren blev opdateret",
      "User could not be updated": "Brugeren kunne ikke opdateres",
      "User could not be forced to reconfigure two-factor": "Brugeren kunne ikke tvinges til at rekonfigurere to-faktor",
      "Users two-factor will be reconfigured by the user": "Brugerens to-faktor vil blive rekonfigureret af brugeren",
      "Users password will be reconfigured by the user": "Brugerens adgangskode vil blive rekonfigureret af brugeren",
      "User could not be forced to reconfigure password": "Brugeren kunne ikke tvinges til at rekonfigurere adgangskode",
      "Are you sure you wish to force the user to change password?": "Er du sikker på du ønsker at tvinge brugeren til at skifte adgangskode?",
      "New password": "Nyt password",
      "New two-factor": "Ny to-faktor",
      "Are you sure you wish to force the user to reconfigure two-factor?": "Er du sikker på du ønsker at tvinge brugeren til at rekonfigurere to-faktor?",
      "The user wil be prompted next time the user logs in, or in his current session, if the user is already logged in": "Brugeren vil blive bedt om at skifte adgangskode næste gang brugeren logger ind, eller i den nuværende session, hvis brugeren allerede er logget ind",
      "Enable user": "Aktiver bruger",
      "Are you sure you wish to enable the user?": "Er du sikker på du ønsker at aktivere brugeren?",
      "Disable user": "Deaktiver bruger",
      "Are you sure you wish to disable the user?": "Er du sikker på du ønsker at deaktivere brugeren?",
      "Send welcome mail": "Send velkomstmail",
      "Are you sure you wish to send a welcome mail to the user?": "Er du sikker på du ønsker at sende en velkomstmail til brugeren?",
      "Resend welcome mail": "Gensend velkomstmail",
      "Are you sure you wish to resend a welcome mail to the user?": "Er du sikker på du ønsker at gensende en velkomstmail til brugeren?",
      "Welcome mail was sent": "Velkomstmail blev sendt",
      "Disable SSL VPN": "Deaktiver SSL VPN",
      "Enable SSL VPN": "Aktiver SSL VPN",
      "Are you sure you wish to disable SSL VPN for the user?": "Er du sikker på du ønsker at deaktivere SSL VPN for brugeren?",
      "Are you sure you wish to enable SSL VPN for the user?": "Er du sikker på du ønsker at aktivere SSL VPN for brugeren?",
      "SSL VPN access updated for the user": "SSL VPN adgang opdateret for brugeren",
      "SSL VPN access could not be updated for the user": "SSL VPN adgang kunne ikke opdateres for brugeren",
      "Services with user as approver": "Services med brugeren som godkender",
      "Services with user as contact": "Services med brugeren som kontakt",
      "Service associations": "Service tilknytninger",
      "Client associations": "Klient tilknytninger",
      "Clients with user as contact": "Klienter med brugeren som kontakt",
      "Copy role and associations": "Kopier rolle og tilknytninger",
      "Copy role, approver- and contact associations": "Kopier rolle, godkender- og kontakt tilknytninger",
      "From": "Fra",
      "To": "Til",
      "Enabled": "Aktiveret",
      "User email is already in use by external contact": "Emailen bliver brugt af en kontakt",
      "Replace contact with user anyways?": "Hvis du fortsætter vil kontakten blive erstattet af brugeren, og dens service og klient tilknytninger vil blive flyttet til brugeren",

      //User roles
      "ORG_ACCESS_PROSPECT": "ORG_AFTALE_GODKENDER",
      "(Org) Access Prospect": "(Org) Aftale Godkender",
      "(Org) Superuser": "(Org) Superbruger",
      "(Org) User Read Only": "(Org) Bruger Read Only",
      "(Org) User Administrator": "(Org) Bruger Administrator",

      //Contacts
      "Contact was created": "Kontakt blev oprettet",
      "External contact was updated!": "Email kontakt blev opdateret",
      "Contact was updated!": "Konktakten blev opdateret",
      "External contact was deleted!": "Email kontakt blev slettet",
      "Delete external contact": "Slet email kontakt",
      "External contact could not be deleted": "Email kontakt kunne ikke slettes",
      "Services with external-contact as contact": "Services med email kontakt som kontakt",
      "Clients with external-contact as contact": "Klienter med email kontakt som kontakt",

      //Portalias
      "Create portalias": "Opret portalias",
      "Protocol": "Protokol",
      "Direction": "Retning",
      "An error occured": "En fejl opstod",
      "Create Access control list": "Opret adgangskontrol liste",
      "Edit Access control list": "Rediger adgangskontrol liste",
      "In use": "I brug",
      "List of services related to protalias": "Liste af services der bruger portaliaset",

      //Contactlabels
      "Contactlabels": "Kontakt labels",
      "Create contactlabel": "Opret kontakt label",
      "Missing contact labels": "Manglende kontakt labels",
      "Contact was removed from organisation!": "Kontakt blev fjernet fra organisationen",
      "Contact label was created": "Kontakt label blev oprettet",
      "Contact label was updated": "Kontakt label blev opdateret",
      "Edit contactlabel": "Rediger kontakt label",
      "Label contexts": "Label kontekster",

      //Authorisation
      "Authorisation": "Autorisation",
      "Delete authorisation": "Slet autorisation",
      "Authorisation information": "Autorisation informationer",
      "Authorisations": "Autorisationer",
      "Select authorisation": "Vælg autorisation",
      "You dont have any authorisations": "Du har ingen autorisationer",
      "Select the authorisation you want to use": "Vælg den autorisation du ønsker at benytte",
      "User organisation": "Bruger organisation",
      "Authorization was updated!": "Autorisation blev opdateret",
      "Authorization could not be updated": "Autorisation kunne ikke opdateres",

      //IP info
      "ASSIGNED": "TILDELT",
      "AVAILABLE": "LEDIG",
      "IN_USE": "I_BRUG",

      // ERROR
      "An error occurred.": "Der opstod en fejl.",
      "Requested resource not found.": "Den ønskede ressource blev ikke fundet.",
      "Invalid pagination parameters.": "Den valgte side er ugyldig.",
      "Missing ip addresses on client.": "Der mangler en ip adresse på klienten.",
      "Invalid agreement status.": "Ugyldig aftalestatus.",
      "Hosting site move not allowed.": "Flytning af hosting site er ikke tilladt.",
      "Conflict detected.": "Der opstod en konflikt.",
      "Subnet overlap detected.": "Overlappende subnet fundet.",
      "Resource not accessible.": "Ressource ikke tilgængelig.",
      "Unknown user.": "Ukendt bruger.",
      "Invalid token.": "Ugyldig adgang.",
      "Service window update not allowed.": "Opdatering af servicevindue er ikke tilladt.",
      "Organization not found.": "Organisation ikke fundet.",
      "Service not found.": "Service ikke fundet.",
      "Service window not found.": "Servicevindue ikke fundet.",
      "Bad gateway.": "Der opstod en fejl i kommunikationen med tredjepart.",
      "Subnet not found.": "Subnet blev ikke fundet.",
      "Hosting site not found.": "Hosting site blev ikke fundet.",
      "Subnet is already in use.": "Subnet er allerede i brug.",
      "Invalid service approver.": "Ugyldig service godkender.",
      "Client not found.": "Klient blev ikke fundet.",
      "External contact not found.": "Email kontakt blev ikke fundet.",
      "Port alias not found.": "Portalias blev ikke fundet.",
      "User not found.": "Bruger blev ikke fundet.",
      "User notification not found.": "Notifikation blev ikke fundet.",
      "IP address not found.": "Ip adresse blev ikke fundet.",
      "Smokeping not found.": "Smokeping blev ikke fundet.",
      "Router not found.": "Router blev ikke fundet.",
      "Agreement not found.": "Aftale blev ikke fundet.",
      "DNS suffix not found.": "DNS-suffix blev ikke fundet.",
      "Contact label not found.": "Kontakt label blev ikke fundet.",
      "Service monitoring not found.": "Service monitorering blev ikke fundet.",
      "Service tag not found.": "Servicetag blev ikke fundet.",
      "Invalid service tag.": "Ugyldig servicetag.",
      "Reason for no encryption required.": "Der mangler en begrundelse for, at der ikke er valgt kryptering.",
      "Service window must have a service.": "Servicevinduet skal have mindst én service tilknyttet.",
      "Service not at service window.": "Servicen er ikke en del af servicevinduet.",
      "Contact not found.": "Kontakt blev ikke fundet.",
      "Duplicate contact found.": "Kontakt eksisterer allerede.",
      "Endpoint not found.": "Endpoint blev ikke fundet.",
      "Conflicting external contact.": "Der eksisterer allerede en email-kontakt med denne email.",
      "Conflicting user.": "Konfliktende bruger.",
      "Invalid contact label.": "Ugyldig kontakt label",
      "Both password and username should not be provided.": "Adgangskode og brugernavn kan ikke angives.",
      "Both password and username should be provided.": "Både adgangskode og brugernavn skal angives.",
      "Keycloak error.": "Fejl i login.",
      "User is not a service approver on the service.": "Du er ikke en servicegodkender på denne service.",
      "Endpoint on a service with approved agreements.": "Dette endpoint bliver brugt på en service med godkendte aftaler.",
      "Email not sent.": "E-mail blev ikke sendt.",
      "User already has authorization to the organization.": "Denne bruger har allerede en autorisation til organisationen.",
      "User cannot have authorization to own organization.": "Brugeren kan ikke have en autorisation til sin egen organisation.",
      "User not allowed to use the provided authorization.": "Bruger har ikke tilladelse til at bruge den angivne autorisation.",
      "Not allowed to give more privileges than own role.": "Man kan ikke tildele en rolle med flere rettigheder end egen rolle.",
      "User does not belong to the organization.": "Bruger tilhører ikke organisationen.",
      "Invalid DNS name.": "DNS-navn er ugyldigt.",
      "Hosting site name already in use.": "Hosting site navn er allerede i brug.",
      "Organization name already in use.": "Organisationsnavn er allerede i brug.",
      "Hosting site SDN V4 ID already in use.": "Hosting site SDN V4 ID er allerede i brug.",
      "DNS suffix already exists.": "DNS-suffix findes allerede.",
      "Portal alias is already in use.": "Portal-alias er allerede i brug.",
      "Service tag already exists on the hosting site.": "Servicegruppen findes allerede på det valgte hosting site.",
      "Authorization was deleted!": "Autorisation blev slettet",
      "Access control list not found.": "ACL kunne ikke findes",
      "DNS suffix is already in use at hosting site.": "DNS-suffix er allerede i brug på hosting sitet.",
      "DNS suffix is already in use at another hosting site": "DNS-suffix er allerede i brug på et andet hosting site.",
      "Create DNS suffix anyways?": "Opret DNS-suffix alligevel?",
      "Update DNS suffix anyways?": "Opdater DNS-suffix alligevel?",
      "Create endpoint anyways?": "Opret endpoint alligevel?",
      "No duplicates allowed": "Dubletter er ikke tilladt",
      "Cannot to user from a different organisation.": "Kan ikke kopiere til en bruger fra en anden organisation",
      "User is already servive approver.": "Brugeren er allerede service godkender"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "da", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;